import React from "react";
import styles from "./Portfolios.module.scss";
import WeddingImage from "../../../../images/portfolios/wedding.png";
import EngagementImage from "../../../../images/portfolios/engagement.png";
import NewBornImage from "../../../../images/portfolios/newborn.png";
import MaternityImage from "../../../../images/portfolios/maternity.png";
import PortraitImage from "../../../../images/portfolios/portrait.png";
import FamilyImage from "../../../../images/portfolios/family.png";
import ArrowButton from "../../arrow-button/ArrowButton";
import cx from "classnames";
import { Portfolio } from "../../../../models/portfolio.model";
import PortfolioContainer from "./portfolio-container/PortfolioContainer";
import { RouteLinks } from "../../../../utils/route-links";
import LinkButton from "../../link-button/LinkButton";

interface Props {
  showBtn?: boolean;
  portfolios: { node: Portfolio }[];
  desktopImages: { [key: string]: string[] };
  mobileImages: { [key: string]: string[] };
  useH1?: boolean;
}

const Portfolios: React.FC<Props> = (props: Props) => {
  const { showBtn = true } = props;

  const getPlaceholderImage = (title: string): string => {
    if (title.toLowerCase().includes("wedding")) {
      return WeddingImage;
    } else if (title.toLowerCase().includes("engagement")) {
      return EngagementImage;
    } else if (title.toLowerCase().includes("newborn")) {
      return NewBornImage;
    } else if (title.toLowerCase().includes("maternity")) {
      return MaternityImage;
    } else if (title.toLowerCase().includes("family")) {
      return FamilyImage;
    } else {
      return PortraitImage;
    }
  };

  return (
    <section className={cx("column", styles.portfolio)} id="portfolios">
      <div className={cx("column", styles.portfolio__row)}>
        {props.useH1 ? (
          <h1 className={styles.portfolio__title}>Portfolio</h1>
        ) : (
          <h3 className={styles.portfolio__title}>Portfolio</h3>
        )}
        {showBtn && (
          <ArrowButton
            text="See all portfolio"
            route={RouteLinks.portfolio}
            dataTestE2E="portfolios"
          />
        )}
      </div>
      <div className={cx("column", styles.portfolio__content)}>
        {props.portfolios.map(
          ({
            node: portfolio,
            node: {
              frontmatter: { slug },
            },
          }) => (
            <PortfolioContainer
              key={slug}
              portfolio={portfolio}
              desktopImage={props.desktopImages[slug]}
              mobileImage={props.mobileImages[slug]}
              placeholder={getPlaceholderImage(portfolio.frontmatter.title)}
              dataTestE2E={slug}
            />
          )
        )}
      </div>
      {showBtn && (
        <LinkButton
          route={RouteLinks.portfolio}
          className={styles.portfolio__btn}
        >
          See all portfolio
        </LinkButton>
      )}
    </section>
  );
};

Portfolios.defaultProps = {
  useH1: false,
};

export default Portfolios;
