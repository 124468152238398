import React from "react";
import FindPackage from "../../homepage/find-package";
import PortfolioSection from "../../homepage/portfolios";
import { graphql, useStaticQuery } from "gatsby";
import { Portfolio } from "../../../../models/portfolio.model";
import { GqlListResponse } from "../../../../models/gql-list-response.model";
import SEO from "../../../commons/seo";
import { RouteLinks } from "../../../../utils/route-links";
import Layout from "../../layout/Layout";
import { MenuOverlayOptions } from "../../../../enums/menu-overlay-options";

interface Props {
  pageContext: any;
}

const query = graphql`
  {
    portfolios: allMarkdownRemark(
      filter: { fields: { category: { eq: "portfolios" } } }
      sort: { order: ASC, fields: [fields___relativePath] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            packages {
              location_slug
            }
          }
        }
      }
    }

    seo: markdownRemark(
      fields: {
        category: { eq: "seo" }
        relativePath: { eq: "seo__portfolios" }
      }
    ) {
      frontmatter {
        metaTitle
        metaDescription
        metaKeywordList
        metaImageName
      }
    }
  }
`;

const Portfolios: React.FC<Props> = (props: Props) => {
  const queryResult = useStaticQuery(query);

  const {
    metaTitle,
    metaDescription,
    metaKeywordList,
    metaImageName,
  } = queryResult.seo.frontmatter;

  const portfoliosRaw: GqlListResponse<Portfolio> = queryResult.portfolios;
  const portfolios = portfoliosRaw.edges;

  return (
    <Layout menuSelected={MenuOverlayOptions.PORTFOLIO}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywordList}
        pageUrl={RouteLinks.portfolio}
        imageUrl={!!metaImageName ? `/previews/${metaImageName}` : undefined}
      />
      <main className="column" data-test-e2e="portfolios-container">
        <PortfolioSection
          showBtn={false}
          desktopImages={props.pageContext.images}
          mobileImages={props.pageContext.mobile_images}
          portfolios={portfolios}
          useH1={true}
        />
        <FindPackage />
      </main>
    </Layout>
  );
};

export default Portfolios;
