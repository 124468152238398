import React from "react";
import styles from "./PortfolioContainer.module.scss";
import cx from "classnames";
import { Portfolio } from "../../../../../models/portfolio.model";
import { Link } from "gatsby";
import { getPortfolioLink } from "../../../../../utils/link.helper";
import { aboveIpadPro } from "../../../../../utils/utils";

interface Props {
  portfolio: Portfolio;
  desktopImage: string[];
  mobileImage: string[];
  placeholder: string;
  dataTestE2E?: string;
}

const PortfolioContainer: React.FC<Props> = (props: Props) => {
  const {
    portfolio: {
      frontmatter: { title },
    },
    portfolio,
    desktopImage,
    mobileImage,
  } = props;

  return (
    <Link
      to={getPortfolioLink(portfolio)}
      className={cx("column", styles.container)}
      data-test-e2e={props.dataTestE2E}
    >
      <figure className={cx(styles.container__img)}>
        <picture>
          <source
            srcSet={!!desktopImage ? desktopImage[0] : props.placeholder}
            media={aboveIpadPro}
            className={styles.image}
          />
          <img
            src={!!mobileImage ? mobileImage[0] : props.placeholder}
            alt={title}
            className={styles.image}
          />
        </picture>
      </figure>
      <p className={styles.container__label}>{title}</p>
    </Link>
  );
};

export default PortfolioContainer;
