import React from "react";
import V1Entry from "../v1-entry";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const PortfolioLayout: React.FC<Props> = (props: Props) => {
  return (
    <V1Entry>
      <div className={cx("main-container", props.className ?? "")}>
        {props.children}
      </div>
    </V1Entry>
  );
};

export default PortfolioLayout;
