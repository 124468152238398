import React from "react";
import Portfolios1 from "../components/v1/pages/portfolios";
import Portfolios2 from "../components/v2/pages/portfolios";

const Portfolios = (props: any) => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <Portfolios1 pageContext={props.pageContext} />;
  } else {
    return <Portfolios2 pageContext={props.pageContext} />;
  }
};

export default Portfolios;
