import React from "react";
import styles from "./ArrowButton.module.scss";
import cx from "classnames";
import { Link } from "gatsby";
import PinkArrowIcon from "../../../images/icons/v2/arrow-right.inline.svg";
import SVGContainer from "../../commons/svg-container/SVGContainer";

interface Props {
  text: string;
  className?: string;
  route?: string;
  dataTestE2E?: string;
}

const ArrowButton: React.FC<Props> = (props: Props) => {
  const { route = "/" } = props;

  return (
    <Link
      to={route}
      className={cx(styles.ArrowButton, `${props.className ?? ""}`)}
      data-test-e2e={props.dataTestE2E}
    >
      {props.text}
      &nbsp;&nbsp;
      <SVGContainer className={styles.icon}>
        <PinkArrowIcon />
      </SVGContainer>
    </Link>
  );
};

export default ArrowButton;
