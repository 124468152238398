import React from "react";
import styles from "./LocusButton.module.scss";
import classNames from "classnames";
import cx from "classnames";
import CurveRightArrow from "../../../images/icons/arrow-right-curve.inline.svg";
import { Link } from "gatsby";
import { IconSizes } from "../../../enums/icon-sizes";
import SVGContainer from "../../commons/svg-container/SVGContainer";

interface Props {
  text: string;
  reverseArrow?: boolean;
  route?: string;
  useYellowCircle?: boolean;
}

const LocusButton: React.FC<Props> = (props: Props) => {
  const { reverseArrow = false, route = "" } = props;
  return (
    <Link to={route} className={cx("locus", styles.LocusButton)}>
      <p className="d8">{props.text}</p>
      <SVGContainer
        className={classNames(reverseArrow && styles.reverseArrow)}
        iconSize={IconSizes.IC__locusBtn}
      >
        <CurveRightArrow />
      </SVGContainer>
      <div
        className={cx(
          styles.circle,
          props.useYellowCircle && styles.circle__yellow
        )}
      />
    </Link>
  );
};

export default LocusButton;
