import React from "react";
import styles from "./SideNav.module.scss";
import RightArrowButton from "../../commons/curve-arrow-button/RightArrowButton";
import FilterIcon from "../../../images/icons/v1/filter.inline.svg";
import { RouteLinks } from "../../../utils/route-links";
import cx from "classnames";
import { Link } from "gatsby";
import { IconSizes } from "../../../enums/icon-sizes";
import SVGContainer from "../../commons/svg-container/SVGContainer";

interface Props {
  children: React.ReactNode;
  iconClick: () => void;
  className?: string;
}

const SideNav: React.FC<Props> = (props: Props) => {
  return (
    <section className={cx("column", styles.sideNav, props.className ?? "")}>
      <div onClick={props.iconClick} className={styles.sideNav__icon}>
        <SVGContainer iconSize={IconSizes.IC__filter}>
          <FilterIcon />
        </SVGContainer>
      </div>
      <div className={styles.children}>{props.children}</div>
      <div className={cx("column", styles.textStart)}>
        <Link to={RouteLinks.whoAmI} className="link">
          <p className="d7">Who am I</p>
        </Link>
        <RightArrowButton
          text="Contact Me"
          className={styles.btn}
          route={RouteLinks.contact}
        />
      </div>
    </section>
  );
};

export default SideNav;
